import { useContext } from 'react'
import { AuthContext, AppNav, Header } from 'components/lib'
import { DocAiIcon, DrFinderIcon, CyborgIcon, GeneralAiIcon, ProvTaxIcon, SearchIcon, TaxAiIcon } from 'icons'
import { SettingsContext } from 'app/settings'
import Style from './app.module.scss'
import { HINTS, LICENSES } from 'utils'

export function AppLayout(props) {
  const auth = useContext(AuthContext)
  const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_name))
  const settingsContext = useContext(SettingsContext)
  const featureFlags = auth?.user?.feature_flags

  const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false
  const shouldHideDocumentUploader = settingsContext?.settings?.disable_tool_document_uploader || false
  const shouldHideProvtaxCalculator = settingsContext?.settings?.disable_tool_provtax_calculator || false

  const isAIView = props.title === 'AI'
  const isDepView = props.title === 'Depreciation Rate Finder'
  const isSeafarerView = props.title === 'Search Results'
  let bgColorClass = ''
  if (isAIView || isSeafarerView) {
    bgColorClass = Style['app-bg-blue']
  } else if (isDepView) {
    bgColorClass = Style['app-bg-orange']
  }

  const navItems = [
    {
      label: 'General AI',
      icon: <GeneralAiIcon />,
      link: '/general-ai',
      hint: HINTS.generalAI,
      view: process.env.REACT_APP_ENV === 'production' && !shouldHideAiGeneral,
    },
    {
      label: 'Tax AI Advanced',
      icon: <TaxAiIcon />,
      link: '/tax-ai',
      hint: HINTS.taxAI,
      view: process.env.REACT_APP_ENV === 'production',
    },
    {
      label: 'AI',
      icon: <CyborgIcon />,
      link: '/ai/tax',
      hint: HINTS.cyborg,
      view: process.env.REACT_APP_ENV !== 'production',
    },
    {
      label: 'Document Questioner',
      icon: <DocAiIcon />,
      link: '/doc-question',
      hint: HINTS.docQuestion,
      view: !shouldHideDocumentUploader, // Visible if shouldHideDocumentUploader is false
    },
    {
      label: 'Depreciation Rate Finder',
      icon: <DrFinderIcon />,
      link: '/dr-finder',
      hint: HINTS.drFinder,
      view: true,
    },
    {
      label: 'Provisional Tax Calculator',
      icon: <ProvTaxIcon />,
      link: '/prov-calc',
      hint: HINTS.provCalc,
      view: !shouldHideProvtaxCalculator, // Visible if shouldHideProvtaxCalculator is false
    },
    {
      label: 'Law Cyborg Search',
      icon: <SearchIcon fill="#fff" height={15} width={15} />,
      link: '/search',
      hint: HINTS.seafarer,
      view: featureFlags?.FEATURE_FLAG_SEAFARER && availableLicensesSet.has(LICENSES.SEAFARER), // Only visible if featureFlag is true and user has license
    },
  ]
  return (
    <div className="flex">
      <AppNav
        items={navItems.filter((item) => item.view)}
        accountActionItems={[
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
        ]}
      />

      <main className={`${Style.app} ${bgColorClass}`}>
        {props.header && <Header title={props.title} options={props.options}></Header>}
        {<props.children {...props.data} />}
      </main>
    </div>
  )
}
