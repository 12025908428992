import FilterCategory from 'components/filterStateful/filterCategory'
import { useCallback, useState } from 'react'

/**
 * FilterCategoryGroup Component
 *
 * A component that manages and displays a group of `FilterCategory` components,
 *
 * The component manages its internal state and notifies the parent component of any filter changes.
 * This means that the parent component should provide the initial filter state and
 * subsequent rerenders with a changed filters prop will not be reflected in the component.
 *
 * @param {Object} props - The properties object.
 * @param {Object} filters - An object representing filter categories, where each key is a category name,
 *                           and each value is a nested object structure defining the filter options
 *                           within that category.
 * @param {function} onFilterChange - A callback function that is triggered whenever the filters state changes.
 *                                    Receives the updated `filters` object as an argument.
 *
 * @example
 * // Example filters structure for a FilterCategoryGroup
 * const filters = {
 *   category1: {
 *     Filter1: { checked: true, value: 'Option 1' },
 *     Filter2: {
 *      SubFilter1: { checked: false, value: 'Option 2' },
 *     }
 *   },
 *   category2: {
 *     FilterA: { checked: true, value: 'Option A' },
 *     FilterB: { checked: false, value: 'Option B' },
 *   }
 * };
 */
const FilterCategoryGroup = ({ filters: filterParam, onFilterChange }) => {
  const [filters] = useState(filterParam)

  const onFilterChangeHandler = useCallback(
    (filterPart, category) => {
      if (onFilterChange) {
        filters[category] = filterPart

        onFilterChange(filters)
      }
    },
    [onFilterChange, filters]
  )

  return (
    <div className="pr-[12px] mt-5 rounded-lg overflow-y-auto">
      {Object.keys(filters).map((category) => (
        <FilterCategory
          key={category}
          filters={filters[category]}
          onFilterChange={onFilterChangeHandler}
          name={category}
          showName={false}
        />
      ))}
    </div>
  )
}

export default FilterCategoryGroup
