import React, { useContext, useEffect, useState } from 'react'
import {
  Animate,
  AuthContext,
  Row,
  PaymentForm,
  usePlans,
  Link,
  useNavigate,
  Event,
  ViewContext,
  Button,
  useAPI,
  Loader,
  Card,
  Checkbox,
} from 'components/lib'
import LCLSubscriberTermsConditionsAgreement from 'components/termsofservice/LCLSubscriberTermsConditionsAgreementStyles'
import { CheckFilledIcon } from 'icons'
import './plan.scss'

const PricingCard = ({ title, price, description, features, buttonLabel, popular, handleClick }) => {
  return (
    <div className={`border bg-white ${popular ? 'border-orange-500' : 's'} relative rounded-lg shadow-md p-6`}>
      {popular && (
        <p className=" text-white text-xs p-1 px-2 rounded-xl bg-orange-600 absolute -top-2.5 left-1/2 -translate-x-1/2 transform">
          Popular
        </p>
      )}
      <h4 className="text-brand-400 pb-2">{title}</h4>
      <p className="text-3xl font-bold text-gray-900">
        {price !== 'Custom' ? (
          <>
            <span className="text-base font-normal">$</span>
            {price}
            <span className="text-base font-normal text-neutral-600"> +GST / user / month</span>
          </>
        ) : (
          price
        )}
      </p>
      <p className="mt-2 text-sm text-neutral-500">{description}</p>
      <ul className="mt-8 space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center ">
            <CheckFilledIcon />
            <span className="ml-2 text-sm text-neutral-800">{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className={`w-full mt-6 py-2 rounded-full border-blue-600 border-2 text-blue-600 hover:bg-blue-600 hover:text-white`}
        onClick={handleClick}
      >
        {buttonLabel}
      </button>
    </div>
  )
}

const Pricing = ({ onPlanSelect }) => {
  const customPlans = {
    title: 'Enterprise',
    price: 'Custom',
    description: 'Suitable for large firms',
    features: [
      'All Elite Features',
      '99.99% Uptime SLA',
      'Custom Subdomain',
      'Custom Database',
      'Tailored In-House Seminars',
      'Dedicated Technical Support',
    ],
    buttonLabel: 'Contact Us',
    buttonDisabled: false,
    popular: false,
  }

  const plans = usePlans()
  const pricingPlans = plans.data?.raw.plans
    ?.slice(0, 2)
    .map((plan) => ({
      id: plan.id,
      title: plan.name,
      price: plan.price.toString(),
      description: plan.name === 'Essential Plan' ? 'Great for any business' : 'For tax technical users',
      features: plan.features?.filter((feature) => feature.checked).map((feature) => feature.name),
      buttonLabel: 'Get Started',
      buttonDisabled: false,
      popular: plan.name === 'Essential Plan',
    }))
    .concat(customPlans)

  const handlePlanSelect = (plan) => {
    if (plan.price === 'Custom') window.location.href = 'https://lawcyborg.com/contact'
    else onPlanSelect(plan)
  }
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-10">
        <h2 className="text-5xl text-primary-dark-blue font-extrabold ">Pricing</h2>
        <p className="text-lg mt-2">Affordably and Transparently Priced.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {pricingPlans?.map((plan, index) => (
          <PricingCard
            key={index}
            title={plan.title}
            price={plan.price}
            description={plan.description}
            features={plan.features}
            buttonLabel={plan.buttonLabel}
            handleClick={() => handlePlanSelect(plan)}
            popular={plan.popular}
          />
        ))}
      </div>
    </div>
  )
}

const InformationModules = ({ onNext }) => {
  const productResponse = useAPI('/api/products-list')
  const infoModules = productResponse?.data?.informationModules || []

  const [selectedModules, setSelectedModules] = useState([])

  useEffect(() => {
    setSelectedModules(infoModules.filter((module) => module.name === 'Tax'))
  }, [productResponse])

  const handleModuleClick = (module) => {
    if (selectedModules.some((m) => m?.name === module.name)) {
      setSelectedModules(selectedModules.filter((m) => m?.name !== module.name))
    } else {
      setSelectedModules([...selectedModules, module])
    }
  }

  const handleNext = () => {
    onNext(selectedModules)
  }

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 text-lg flex justify-between items-center font-semibold">
        Information Modules
        <Button text={'Next'} disabled={selectedModules.length < 1} small action={handleNext} color={'brand3'} />
      </div>
      <div className="grid grid-cols-4 gap-4">
        {productResponse?.loading ? (
          <Loader />
        ) : (
          infoModules?.length > 0 &&
          infoModules?.map((module) => (
            <div
              key={module?.name}
              className={`p-4 relative border text-sm rounded items-center ${
                module?.name !== 'Tax'
                  ? '!bg-gray-200 cursor-default'
                  : 'cursor-pointer hover:border-blue-300 hover:bg-blue-50'
              } ${selectedModules.some((m) => m?.name === module?.name) ? 'bg-blue-100 border-blue-500' : 'bg-white'}`}
              onClick={() => module.name === 'Tax' && handleModuleClick(module)}
            >
              <input
                type="checkbox"
                checked={selectedModules.some((m) => m?.name === module?.name)}
                className="absolute top-5 right-3 focus:border-pink rounded border-gray-300 appearance-none peer shrink-0 w-[18px] h-[18px] border-2 bg-[#CBD5E1] checked:bg-brand-500 checked:border-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:brand-400 hover:ring-2 hover:brand-400 disabled:border-[#C6C6C6] disabled:bg-[#C6C6C6]"
                disabled={module.name !== 'Tax'}
              />
              <p className="mr-3">{module.name}</p>
              <p>${module.price_per_unit}</p>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

const CheckoutPlan = ({ selectedPlan, selectedModules }) => {
  const plans = usePlans()
  const totalValue =
    selectedModules.reduce((acc, module) => {
      return acc + parseFloat(module.price_per_unit)
    }, 0) + parseFloat(selectedPlan.price)

  const viewContext = useContext(ViewContext)
  const context = useContext(AuthContext)
  const navigate = useNavigate()

  // Use useState to manage termsAndConditions state
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)

  const isTermsAccepted = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.notification.show('Please accept the Subscriber Terms & Conditions', 'error', true)
    }
    return termsAndConditionsAccepted
  }
  if (!plans.data) return false
  const handleTermsAndConditionsChange = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.modal.show(
        {
          text: <LCLSubscriberTermsConditionsAgreement handleClose={() => viewContext.modal.hide()} />,
          className: 'customModal',
          form: {},
          buttonText: 'Accept',
        },
        () => {
          setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
          viewContext.modal.hide()
        }
      )
    }
  }
  return (
    <div className="py-8">
      <div className="grid grid-cols-5 gap-4">
        <div className="bg-white p-6 rounded-lg col-span-3 shadow-md">
          <div className="flex justify-between items-center border-b pb-4 mb-4">
            <h2 className="text-xl font-semibold">Plan</h2>
            <span className="text-gray-600">{selectedPlan.title}</span>
          </div>

          {/* Order Summary Section */}
          <div className="mb-4 border-b pb-4">
            <h3 className="text-lg font-semibold text-primary-dark-blue mb-2">Modules</h3>
            <ul className="space-y-1">
              {selectedModules.map((module, index) => (
                <li key={index} className="flex justify-between">
                  <span>{module?.name}</span>
                  <span>${module?.price_per_unit ?? 0}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="font-semibold  ">
            {/* Modules Section */}

            <h3 className="text-lg  text-primary-dark-blue mb-2">Order Summary</h3>

            <div className="flex justify-between mb-1">
              <span>Total Modules</span>
              <span>{selectedModules?.length}</span>
            </div>
            <div className="flex justify-between">
              <span>Total Value</span>
              <span>${totalValue} / Month</span>
            </div>
          </div>
        </div>

        {/* Payment Section */}
        <div className="bg-white p-6 rounded-lg h-fit shadow-md col-span-2">
          <h3 className="text-lg font-semibold mb-8">Pay With Card</h3>

          <div className="flex items-center gap-2 mb-4">
            <input
              type="checkbox"
              id="terms"
              autoFocus
              onClick={handleTermsAndConditionsChange}
              checked={termsAndConditionsAccepted}
              className="focus:border-pink rounded border-gray-300 appearance-none peer shrink-0 w-[18px] h-[18px] border-2 bg-[#CBD5E1] checked:bg-brand-500 checked:border-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:brand-400 hover:ring-2 hover:brand-400 disabled:border-[#C6C6C6] disabled:bg-[#C6C6C6]"
            />
            <label htmlFor="terms" className="text-sm">
              I accept the{' '}
              <button
                onClick={handleTermsAndConditionsChange}
                className="text-sky-500 hover:text-sky-700 focus:outline-none"
              >
                Subscriber Terms & Conditions
              </button>
            </label>
          </div>
          <PaymentForm
            inputs={{
              plan: {
                label: 'Plan',
                type: 'hidden',
                value: selectedPlan?.id,
              },
              informationModules: {
                type: 'hidden',
                value: selectedModules.map((module) => ({
                  module_name: module?.name,
                  id: module?.price_id,
                })),
              },
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true,
              },
            }}
            onBeforeSubmit={isTermsAccepted}
            url="/api/account/plans"
            method="POST"
            buttonText="Save Plan"
            callback={(res) => {
              // save the plan to context, then redirect
              Event('selected_plan', {
                // Theres only one element in the array which is the $50 per month plan so just use that,
                // as soon as we have multiple plans we can swap back to the select box method.
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
              })
              context.update({
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
                subscription: res.data.subscription,
              })
              navigate(res.data.onboarded ? '/' : '/welcome')
              // navigate(res.data.onboarded ? '/dashboard' : '/welcome');
            }}
          />
        </div>
      </div>
    </div>
  )
}

const SignupOldPlan = () => {
  const context = useContext(AuthContext)
  const viewContext = useContext(ViewContext)
  const navigate = useNavigate()
  const plans = usePlans()

  // Use useState to manage termsAndConditions state
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)

  const handleTermsAndConditionsChange = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.modal.show(
        {
          text: <LCLSubscriberTermsConditionsAgreement handleClose={() => viewContext.modal.hide()} />,
          className: 'customModal',
          form: {},
          buttonText: 'Accept',
        },
        () => {
          setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
          viewContext.modal.hide()
        }
      )
    }
  }

  const isTermsAccepted = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.notification.show('Please accept the Subscriber Terms & Conditions', 'error', true)
    }
    return termsAndConditionsAccepted
  }
  if (!plans.data) return false
  return (
    <Animate type="pop">
      <Row>
        <Card loading={false} restrictWidth center>
          <div class="flex flex-col justify-center items-center text-center mb-4">
            <span class="font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 pb-10">
              Early adopter plan
            </span>
            <span class="text-5xl font-extrabold text-brand-500">$39</span>
            <span class="text-brand-500">(excluding GST)</span>
            <span class="text-gray-500 mt-1">per user per month</span>
          </div>
          <div class="flex mt-10 mb-4">
            <Checkbox
              name="termsAndConditions"
              option={
                <p>
                  I accept the{' '}
                  <button
                    onClick={handleTermsAndConditionsChange}
                    className="text-sky-500 hover:text-sky-700 focus:outline-none"
                  >
                    Subscriber Terms & Conditions
                  </button>
                </p>
              }
              checked={termsAndConditionsAccepted}
              callback={handleTermsAndConditionsChange}
            />
          </div>
          <PaymentForm
            inputs={{
              plan: {
                label: 'Plan',
                type: 'hidden',
                value: plans.data.list[0].value,
              },
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true,
              },
            }}
            onBeforeSubmit={isTermsAccepted}
            url="/api/account/plan"
            method="POST"
            buttonText="Save Plan"
            callback={(res) => {
              // save the plan to context, then redirect
              Event('selected_plan', {
                // Theres only one element in the array which is the $50 per month plan so just use that,
                // as soon as we have multiple plans we can swap back to the select box method.
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
              })
              context.update({
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
                subscription: res.data.subscription,
              })
              navigate(res.data.onboarded ? '/' : '/welcome')
              // navigate(res.data.onboarded ? '/dashboard' : '/welcome');
            }}
          />

          <div className="mt-4">
            <Link url="/account/profile" text="Manage Your Account" />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}

export const SignupPlan = () => {
  const auth = useContext(AuthContext)
  const isMultiProductEnabled = auth?.user?.feature_flags?.FEATURE_FLAG_MULTI_PRODUCT
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [selectedModules, setSelectedModules] = useState([])

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan)
    setCurrentStep(1)
  }

  const handleModulesNext = (modules) => {
    setSelectedModules(modules)
    setCurrentStep(2)
  }
  if (!isMultiProductEnabled) return <SignupOldPlan />

  return (
    <Animate type="pop">
      <Row className="!bg-[#F3F8FA] min-h-[calc(100vh-10rem)]">
        {currentStep > 0 && (
          <Button textOnly icon="arrow-left" text="Back" action={() => setCurrentStep((prev) => prev - 1)} />
        )}
        {currentStep === 0 && <Pricing onPlanSelect={handlePlanSelect} />}
        {currentStep === 1 && <InformationModules onNext={handleModulesNext} />}
        {currentStep === 2 && (
          <CheckoutPlan selectedPlan={selectedPlan} currentStep={currentStep} selectedModules={selectedModules} />
        )}
        <div className="mt-4">
          <Link url="/account/profile" text="Manage Your Account" />
        </div>
      </Row>
    </Animate>
  )
}
