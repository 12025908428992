import { useState, useRef, useContext, useEffect, useCallback, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  ChatInputForm,
  ConversationDisplay,
  ChatDisclaimer,
  useConversation,
  useConversationAutoScroll,
  AuthContext,
} from 'components/lib'

import { nzTax, nzNewTax } from './definitions'
import ChatHistory from 'components/ai/chatHistory'
import TaxAiChatSetting from 'components/ai/taxAiChatSetting'
import { practiceAreas } from '@lawcyborg/packages'

/*
 * @param {Array} flatData - Array of practice areas
 * @param {Array} checkedValues - Array of values to be checked, if multiple checkboxes have the same value
 *                                all of them will be checked.
 * @returns {Object} - FilterCategory filter Object that can be used in the FilterCategory component.
 */
export const practiceAreaNamespacesToFilterCategory = (flatData, checkedValues) => {
  const result = {}
  const namespaceMap = {}

  for (const { namespace, name, grouping } of flatData) {
    if (!grouping) continue

    const categories = grouping.split('/')
    let currentLevel = result
    let currentNamespace = namespaceMap

    categories.forEach((category) => {
      currentLevel[category] ??= {}
      currentNamespace[category] ??= {}

      currentLevel = currentLevel[category]
      currentNamespace = currentNamespace[category]
    })

    currentLevel[name] = { checked: checkedValues ? checkedValues.includes(namespace) : true, value: namespace }
    currentNamespace[name] = namespace
  }
  return result
}

export function LawCyborg() {
  const chatLogRef = useRef()
  const [searchParams, setSearchParams] = useSearchParams()
  const [autoScroll, setAutoScroll] = useState(true)
  const context = useContext(AuthContext)
  const featureFlags = context.user.feature_flags
  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    mode,
    setMode,
    branch,
    swapBranch,
    loadConversation,
    conversationId,
    stopStreaming,
    loadConversations,
    historyCached,
    setConversationConfig,
    selectedNamespaces,
    setSelectedNamespaces,
    setAuto,
  } = useConversation(featureFlags?.FEATURE_FLAG_NEW_TAX_AI ? nzNewTax : nzTax)

  useEffect(() => {
    const config = featureFlags?.FEATURE_FLAG_NEW_TAX_AI ? nzNewTax : nzTax
    setConversationConfig(config)
  }, [featureFlags, setConversationConfig])

  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>

  const filters = featureFlags?.FEATURE_FLAG_NEW_TAX_AI
    ? {
        'Document Types': practiceAreaNamespacesToFilterCategory(practiceAreas.tax.namespaces, selectedNamespaces),
      }
    : { 'Document Types': nzTax.namespaces }

  const onSelectionChange = useCallback(
    (namespaces) => {
      const params = new URLSearchParams(window.location.search)
      params.delete('namespaces')
      namespaces.forEach((ns) => params.append('namespaces', ns))
      setSearchParams(params)

      // Update component state
      setSelectedNamespaces(namespaces)
    },
    [setSelectedNamespaces]
  )
  const onAutoChange = useCallback(
    (auto) => {
      const params = new URLSearchParams(window.location.search)
      params.delete('auto')
      params.set('auto', auto)
      setSearchParams(params)
      setAuto(auto)
    },
    [setAuto]
  )

  useLayoutEffect(() => {
    const namespacesFromParams = searchParams.getAll('namespaces')
    const autoFromParams = searchParams.get('auto')
    if (autoFromParams) onAutoChange(autoFromParams === 'true')
    if (namespacesFromParams.length > 0) setSelectedNamespaces(namespacesFromParams)
  }, [])

  // LV LCEN-149 20231113 We allow for a total of 10 questions
  // in this chat which is 10 user messages plus the previous 9 responses (19 total).
  // Note, even though the max is 19, this does not block the final response so there
  // will still be 20 messages after the 10th question.
  const MAX_CHAT_LENGTH = 19
  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative">
      <TaxAiChatSetting
        mode={mode}
        setMode={setMode}
        filters={filters}
        onSelectionChange={onSelectionChange}
        onAutoChange={onAutoChange}
      />

      <ChatHistory
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f3f8fa'}
        onOpen={loadConversations}
        loading={!historyCached}
        enableRefresh={exchanges.length}
        isStreaming={!loading && !canSend}
        isNewTaxAI={featureFlags?.FEATURE_FLAG_NEW_TAX_AI}
      />

      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%] no-scrollbar" id="chat">
        {!exchanges.length && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={nzTax.disclaimers}
          />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
        />
      </div>

      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        {/* <RefreshButton enabled={exchanges.length} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          inputReadOnly={exchanges.length >= MAX_CHAT_LENGTH}
          placeholderText={
            exchanges.length >= MAX_CHAT_LENGTH
              ? 'A maximum of 10 questions can be asked in this chat. Please refresh to start a new conversation.'
              : 'Ask a question...'
          }
        />
      </div>
    </div>
  )
}
